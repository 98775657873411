import Vue from "vue";
import VueRouter from "vue-router";
//import home from '@/views/home/index.vue'
import errorf from '@/views/error/404/index.vue'
import storage from "@/utils/storage";
import Fingerprint2 from "fingerprintjs2";

// 现货资源
const spot = [{
	path: '/spot/index',
	name: 'spot',
	component: () => import('@/views/spot/index.vue')
}, ]
// 供应链服务
const chain = [{
	path: '/supply/index',
	name: 'supply',
	component: () => import('@/views/supply/index.vue')
}, ]
// 关于我们
const aboutUs = [{
	path: '/aboutUs/index',
	name: 'aboutUs',
	component: () => import('@/views/aboutUs/index.vue')
}, ]

const routes = [{
		path: '/',
		redirect: 'index'
	},
	{
		path: '/index', //首页
		name: 'home',
		component: () => import('@/views/home/index.vue')
	},
	{
		path: '*',
		name: 'error',
		component: errorf
	},
	...spot,
	...chain,
	...aboutUs,

];


// fix vue-router NavigationDuplicated
Vue.use(VueRouter);

// 导出路由 在 main.js 里使用
const router = new VueRouter({
	mode: 'history',
	routes,
});

router.beforeEach((to, from, next) => {
	if (!storage.getStorage("fingerprint")) {
		Fingerprint2.get(components => {
			// 参数只有回调函数时，默认浏览器指纹依据所有配置信息进行生成
			const values = components.map(component => component.value); // 配置的值的数组
			const murmur = Fingerprint2.x64hash128(values.join(""), 31); // 生成浏览器指纹
			storage.setStorage("fingerprint", murmur);
		});
		if (to.path == "//") {
			setTimeout(next({
				name: 'home'
			}), 300);
		} else {
			setTimeout(next(), 300);
		}
	} else {
		if (to.path == "//") {
			next({
				name: 'home'
			})
		} else {
			next()
		}
	}
});
export default router;