import * as HttpServices from "@/utils/http";
import {
	getLoginPayload,
	getPayload
} from "@/utils/server";
import dayjs from 'dayjs'
export default {
	namespaced: true,
	moduleName: "home", // 模块名
	state: {

	},
	mutations: {
		setUserInfo: (state, data) => {
			state.userInfo = data;
		},
		setHomeMallDataIndex: (state, data) => {
			state.homeMallList = data;
			if (state.homeMallList.length > 6) {
				state.homeMallList.length = 6;

			}
			console.log('首页-现货：', state.homeMallList)
		},
		setOrderTrendChartPc: (state, data) => {
			state.orderTrendChartPcData = data;
		},
		setTransactionOrder: (state, data) => {
			data.map(obj => {
				obj.sale = obj.sale.toFixed(2);
				obj.rq_ = dayjs(obj.rq_).format("MM-DD HH:mm");
			})
			state.transactionOrderList = data;
		},
		setBidActivityWinne: (state, data) => {
			if (data.length > 5) {
				data.length = 5
			}
			data.map(obj => {
				obj.appraiseTime = dayjs(obj.appraiseTime).format("YYYY-MM-DD");
			})
			state.bidActivityWinnerList = data;
		},
		setEngineeringList: (state, data) => {
			state.engineeringList = data;
		},
		setAreaCity: (state, data) => {
			state.cityList = data;
			state.cityName = state.cityList[0];
		},
		setCity: (state, data) => {
			state.cityName = data;
		},
		setCompany: (state, data) => {
			if (data.length > 3) {
				data.length = 3
			}
			data.forEach(item => {
				if (item.goods) {
					if (item.goods.length > 2) {
						item.goods.length = 2;
					}
					item.goods.map(obj => {
						obj.createTime = dayjs(obj.createTime).format("MM-DD HH:mm")
					})
				}
			});
			state.companyList = data;
		},
		setRegion: (state, data) => {
			state.regionList = data;
		},
		setPM: (state, data) => {
			state.PMList = data;
		},
		setCityPriceTrendsNewList: (state, data) => {
			state.cityPriceTrendsNewList = data;
		},
	},
	actions: {
		//价格指导-列表
		async priceTrendsList({
			state,
			commit
		}, params) {
			try {
				state.pageLoading = true;
				const payload = getPayload("hhd207002", params);
				const res = await HttpServices.startApiReq(payload);
				if (res.msgcode === 1) {
					return res
				}
			} finally {
				state.pageLoading = false;
			}
		},
	}
};