import router from "@/router";
import * as CryptoJS from "crypto-js";
import storage from "@/utils/storage";
import * as HttpServices from "@/utils/http";
import {
	getPayload,
	getLoginPayload
} from "@/utils/server";
import {
	binterface
} from "@/utils";
import {
	Message,
	MessageBox
} from "element-ui";
import {
	getEvnConfig
} from "@/utils/appConfig";
export default {
	namespaced: true,
	state: {

	},
	mutations: {

	},
	actions: {
		async clear({
			dispatch
		}) {
			util.cookies.remove("ddmg_hanhe_logininfo", {
				domain: ".ddmg.com",
				path: "/"
			});
			util.cookies.remove("mall_token", {
				domain: ".ddmg.com",
				path: "/"
			});
			storage.removeStorage("userInfo");
			storage.removeStorage("kkun_uid");
			storage.removeStorage("kkun_keys");
			storage.removeStorage("rsa_pub");
			storage.removeStorage("company_id");
			storage.removeStorage("user_id");
		},
		/**
		 * @description 注销用户并返回登录页面
		 * @param {Object} context
		 * @param {Object} payload confirm {Boolean} 是否需要确认
		 */
		logout({
			commit,
			dispatch
		}, {
			confirm = false
		} = {}) {
			dispatch("clear")
			let type = getEvnConfig();
			let hostUrl = window.location.href;
			hostUrl = hostUrl.substring(hostUrl.lastIndexOf("#") + 1, hostUrl.length);
			hostUrl = hostUrl.substring(hostUrl.lastIndexOf("/") + 1, hostUrl.length);
			let loginUrl = "work.ddmg.com/#/login?front_path=" + hostUrl;
			let url = "";
			if (type == 0) {
				url = "https://" + loginUrl;
			} else if (type == 1) {
				url = "https://p" + loginUrl;
			} else if (type == 2) {
				url = "https://t" + loginUrl;
			} else if (type == 3) {
				url = "https://d" + loginUrl;
			}
			//let token = util.cookies.get("mall_token");
			let data = util.cookies.get("ddmg_hanhe_logininfo");
			if (data) {
				return true
			} else {
				console.log("url", url);
				window.location.href = url;
			}

		},
	}
};