import Vue from "vue"
import App from "./App.vue"
import {
	getEvnConfig
} from "@/utils/appConfig"
// store
import store from "@/store/index"
// 引入echarts
// import echarts from 'echarts'
// Vue.prototype.$echarts = echarts
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
Vue.use(ElementUI)
Vue.config.ignoredElements = ['wx-open-launch-weapp']
// 菜单和路由设置
import router from "./router"
Vue.config.devtools = true
import VueRouter from "vue-router"
import storage from "@/utils/storage";
Vue.config.productionTip = false
Vue.use(VueRouter)
new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app")